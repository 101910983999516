@import url(https://fonts.googleapis.com/css?family=Oswald&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media(min-width: 960px){
  .content{
    padding-left: 300px;
    max-width: 1200px;
    margin: auto;
  }
}
.home-page{
    padding: 20px;
  padding-top: 25vh;
}

.card-title{
    padding: 20px;
}

.home-page h1, h2, label{
  font-family: 'Oswald', sans-serif;
}
.App {
  text-align: center;
  overflow-x: hidden;

  background-image: url(/static/media/image.f799d63f.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-attachment: fixed;
	height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  
}

.App-link {
  color: #09d3ac;
}

.center{
  text-align:center;
}

.error-message{
  font-weight: bold;
}
.feed-page{
    padding: 20px;
}

.title{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card{
    margin: 6px;
}
