@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');
.home-page{
    padding: 20px;
  padding-top: 25vh;
}

.card-title{
    padding: 20px;
}

.home-page h1, h2, label{
  font-family: 'Oswald', sans-serif;
}