.feed-page{
    padding: 20px;
}

.title{
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card{
    margin: 6px;
}