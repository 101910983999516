.App {
  text-align: center;
  overflow-x: hidden;

  background-image: url('./assets/image.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-attachment: fixed;
	height: 100vh;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  
}

.App-link {
  color: #09d3ac;
}

.center{
  text-align:center;
}

.error-message{
  font-weight: bold;
}